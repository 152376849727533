import "./Widgets.css"
import { useEffect, useState } from "react"

function Widgets() {
  const importImages = async () => {
    const [
      alertsBullet,
      feesBullet,
      overlaysBullet,
      paymentMethodsBullet,
      widgetsBullet,
      withdrawalsBullet,
      donationPreviewMessage,
      donationPreviewGame
    ] = await Promise.all([
      import("../../../assets/images/features/bullets/alerts-bullet.svg"),
      import("../../../assets/images/features/bullets/fees-bullet.svg"),
      import("../../../assets/images/features/bullets/overlays-bullet.svg"),
      import("../../../assets/images/features/bullets/payment-methods-bullet.svg"),
      import("../../../assets/images/features/bullets/widgets-bullet.svg"),
      import("../../../assets/images/features/bullets/withdrawals-bullet.svg"),
    ])

    return {
      alertsBullet: alertsBullet.default,
      feesBullet: feesBullet.default,
      overlaysBullet: overlaysBullet.default,
      paymentMethodsBullet: paymentMethodsBullet.default,
      widgetsBullet: widgetsBullet.default,
      withdrawalsBullet: withdrawalsBullet.default,
    }
  }

  const [images, setImages] = useState({
    alertsBullet: null,
    feesBullet: null,
    overlaysBullet: null,
    paymentMethodsBullet: null,
    widgetsBullet: null,
    withdrawalsBullet: null,
  })

  useEffect(() => {
    importImages().then((importedImages) => {
      setImages(importedImages)
    }).catch((error) => {
      console.error("Failed to import images:", error)
    })
  }, [])

  return (
    <div className={"widgets-wrapper section-wrapper"}>
      <h1 className={"section-header-smaller text-center"}>
        Why us?
      </h1>
      <h3 className={"widgets-description section-text text-center"}>
        Let's describe our perks in a few words
      </h3>
      <div className={"widgets-bullets"}>
        <div className={"widgets-column-1"}>
          <div className={"bullet-point"}>
            <img className={"bullet"} src={images.withdrawalsBullet} alt={""} />
            <div>
              <h2 className={"section-subheader-smaller"}>
                Low service fees
              </h2>
              <p className={"section-text"}>
                The fees on the platform are the lowest among top donation platforms. Optimized process allows smaller
                donations (starting at $2—check our exact minimums!), thanks to smart technology, which reduces network
                fees.
              </p>
            </div>
          </div>
          <div className={"bullet-point"}>
            <img className={"bullet"} src={images.paymentMethodsBullet} alt={""} />
            <div>
              <h2 className={"section-subheader-smaller"}>
                Any Currency, Anytime, Anywhere
              </h2>
              <p className={"section-text"}>
                No matter where you live or where your audience is located, they can support you in nearly any
                cryptocurrency without the hassle of endless KYC forms or rejections due to “incorrect” jurisdictions.
                No central authority controls your every move. You’re in charge of your work, your fans, your earnings.
              </p>
            </div>
          </div>
          <div className={"bullet-point"}>
            <img className={"bullet"} src={images.paymentMethodsBullet} alt={""} />
            <div>
              <h2 className={"section-subheader-smaller"}>
                Support for over 80 different tokens
              </h2>
              <p className={"section-text"}>
                Our platform enables content creators to accept 80+ different cryptocurrencies, with a convenient swap
                feature that converts donations into the streamer’s preferred token.
              </p>
            </div>
          </div>
          <div className={"bullet-point"}>
            <img className={"bullet"} src={images.alertsBullet} alt={""} />
            <div>
              <h2 className={"section-subheader-smaller"}>
                Privacy and Security
              </h2>
              <p className={"section-text"}>
                When you use a direct wallet address for donations, anonymity is compromised. Anyone can view the
                wallet’s transaction history, seeing who donated, the amounts, and even the balance. At Streamiverse, we
                handle the entire transaction flow, ensuring that both the streamer’s and the donors’ information
                remains private, secure, and anonymous.
              </p>
            </div>
          </div>
        </div>
        <div className={"widgets-column-2"}>
          <div className={"bullet-point"}>
            <img className={"bullet"} src={images.alertsBullet} alt={""} />
            <div>
              <h2 className={"section-subheader-smaller"}>
                An Effortless, User-Friendly Experience
              </h2>
              <p className={"section-text"}>
                With Streamiverse, getting started is easy, even if you’re new to streaming or crypto. Registration and
                setup take just minutes, with a familiar, bank-like interface that feels intuitive and accessible. Our
                platform is designed to look and operate like the apps you already know, so everything flows seamlessly
                from the start.
              </p>
            </div>
          </div>
          <div className={"bullet-point"}>
            <img className={"bullet"} src={images.widgetsBullet} alt={""} />
            <div>
              <h2 className={"section-subheader-smaller"}>
                All types of on-stream notifications
              </h2>
              <p className={"section-text"}>
                Streamiverse goes beyond simply accepting crypto donations; we bring your audience into the action.
                Every crypto donation triggers an engaging overlay or message visible on-stream, adding excitement for
                viewers and boosting real-time interaction. It’s a powerful way to entertain and involve your audience,
                enhancing both engagement and visibility.
              </p>
            </div>
          </div>
          <div className={"bullet-point"}>
            <img className={"bullet"} src={images.feesBullet} alt={""} />
            <div>
              <h2 className={"section-subheader-smaller"}>
                A digital asset wallet to manage all payments in one place
              </h2>
              <p className={"section-text"}>
                Streamiverse provides each streamer with a dedicated digital asset wallet, created effortlessly as
                part of the setup process. All crypto donations are directed to this wallet, allowing streamers to
                manage all incoming payments in one secure place. Streamers can then transfer their crypto anywhere,
                whenever they choose. While fiat withdrawals aren’t available just yet, they’re coming soon for even
                more flexibility.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Widgets
